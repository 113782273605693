import i18n from "i18next";
import React, { useState } from "react";
import Select, { components } from "react-select";

const options = [
  { value: "en", label: "English", icon: "/images/en.png" },
  { value: "fr", label: "Français", icon: "/images/fr.png" },
];

const Translation = () => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleLanguageChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    i18n.changeLanguage(selectedOption.value);
  };

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleLanguageChange}
      styles={{
        control: (provided) => ({
          ...provided,
          width: 146,
          borderRadius: 0,
          borderColor: "gray",
        }),
        option: (provided, state) => ({
          ...provided,
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
          fontSize: 16,
          fontWeight: state.isSelected ? "bold" : "normal",
          color: state.isSelected ? "white" : "black",
          backgroundColor: state.isSelected ? "#007bff" : "white",
          cursor: "pointer",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: selectedOption.value === "en" ? "#007bff" : "#28a745",
        }),
      }}
      components={{
        Option: ({ data, ...props }) => (
          <components.Option {...props}>
            <img
              src={data.icon}
              alt={data.label}
              style={{ width: 20, marginRight: 10 }}
            />
            {data.label}
          </components.Option>
        ),
        SingleValue: ({ data, ...props }) => (
          <components.SingleValue {...props}>
            <img
              src={selectedOption.icon}
              alt={selectedOption.label}
              style={{ width: 20, marginRight: 10 }}
            />
            {selectedOption.label}
          </components.SingleValue>
        ),
      }}
    />
  );
};

export default Translation;
