import { Col, Image, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { PopupButton } from "react-calendly";
import { useTranslation } from "react-i18next";

function About() {
  const { t, i18n } = useTranslation();
  return (
    <Container
      fluid
      className="about-container d-flex flex-column bg-white"
      id="about"
    >
      <Row className="text-block mt-5" />
      <Row className="text-block mt-5" />
      <Row className="flex-grow-1">
        <Col md={1} />
        <Col className="avatar-component" md={2}>
          <Image
            rounded
            src="images/me.jpg"
            className="img-responsive"
            width="250"
            height="190"
          />
        </Col>
        <Col md={1} />
        <Col md={6}>
          <div className="row">
            <div className="columns contact-details text-start display-linebreak">
              <h2
                className="text-md-left"
                style={{
                  fontFamily: "Arial",
                  fontSize: "2.4em",
                  marginTop: "12px",
                  marginBottom: "20px",
                }}
              >
                {t("about_me.title")}
              </h2>
              <p
                className="text-md-left"
                style={{
                  fontSize: "1em",
                  marginBottom: "20px",
                }}
              >
                {t("about_me.description")}
              </p>
              <button
                onClick={() => {
                  window.location.hash = "contact";
                }}
              >
                {t("contact.contactMe")}
              </button>{" "}
              {t("contact.or")}{" "}
              <PopupButton
                url="https://calendly.com/daoud-amine"
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById("root")}
                text={t("contact.schedule")}
              />
            </div>
            <div className="columns download">
              <p></p>
            </div>
          </div>
        </Col>
        <Col md={3} />
      </Row>
    </Container>
  );
}

export default About;
