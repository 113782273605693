import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { useTranslation } from "react-i18next";
import Language from "./Language";

function Header() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Navbar
        bg="light"
        expand="sm"
        fixed="top"
        className="mb-3"
        collapseOnSelect
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mr-auto navbar-collapse"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href="#home" className="mx-2">
                {" "}
                {t("navBar.home")}
              </Nav.Link>
              <Nav.Link href="#about" className="mx-2">
                {" "}
                {t("navBar.about")}
              </Nav.Link>
              <Nav.Link href="#resume" className="mx-2">
                {" "}
                {t("navBar.resume")}
              </Nav.Link>
              {/* <Nav.Link href="#projects" className="mx-2">
                {" "}
                {t("navBar.projects")}
              </Nav.Link> */}
              <Nav.Link href="#contact" className="mx-2">
                {" "}
                {t("navBar.contact")}
              </Nav.Link>

              <Nav.Link href="/blog"> {t("navBar.blog")}</Nav.Link>
              {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
          <Language />
        </Container>
      </Navbar>
      {/* Custom CSS for modifying collapsed Navbar width */}
      <style>
      {`
      @media (max-width: 767.98px) {
          .navbar-toggler.collapsed {
            width: 17% !important; /* You can adjust the width as needed */
          }
        }
      `}
      </style>
    </>
  );
}

export default Header;
