import React from "react";
import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t, i18n } = useTranslation();
  const networks = t("social", { returnObjects: true }).map((item, index) => {
    return (
      <div key={index}>
        <ListGroup.Item
          className="ml-3 rounded"
          variant="dark"
          style={{ margin: "5px" }}
        >
          <a href={item.url}>
            <i className={item.className}></i>
          </a>
        </ListGroup.Item>
      </div>
    );
  });

  return (
    <Container fluid className="w-100 flex-column col p-0" id="footerContainer">
      <footer
        className="w-100 m-0"
        style={{
          opacity: 1,
          overflowX: "hidden",
          background: "black",
          color: "white",
        }}
      >
        <Row className="mt-3"></Row>
        <Row className="mt-3">
          <div className="twelve columns">
            <ListGroup
              horizontal
              className="pl-3"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {networks}
            </ListGroup>
          </div>
        </Row>
        <Row>
          <ul className="copyright mt-2">
            <li style={{ listStyle: "none" }}>
              &copy; Copyright 2022 Amine Daoud
            </li>
            <li style={{ listStyle: "none" }}>
              Design by{" "}
              <a title="Styleshout" href={t("contact.website")}>
                Amine Daoud
              </a>
            </li>
          </ul>
        </Row>
      </footer>
    </Container>
  );
}

export default Footer;
