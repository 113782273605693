import React from "react";
import "./App.css";

import { useState } from "react";
import Blog from "./Blog";
import BlogPost from "./BlogPost";
import Home from "./Home";
import "./i18n";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

function App() {
  const [showIcon, setShowIcon] = useState(false);

  const onClicked = () => {
    setShowIcon(true);
  };
  return (
    <div className="App">
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
