import { Col, Row } from "react-bootstrap";
import "./Rate.css";
function Rate({ keyword, level }) {
  const mainCircles = [...Array(level)].map((v, i) => (
    <li key={i} style={{ display: "inline-block" }}>
      <div className="circle"></div>
    </li>
  ));
  const remainingCircles = [...Array(5 - level)].map((v, i) => (
    <li key={i} style={{ display: "inline-block" }}>
      <div className="circle" style={{ background: "lightgrey" }}></div>
    </li>
  ));
  return (
    <div className="rate-container">
      <Row>
        <Col md={1}>{keyword}</Col>
        <Col md={5}>
          <ul>
            {mainCircles}
            {remainingCircles}
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default Rate;
