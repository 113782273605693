import React, { useMemo, useState } from 'react';
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkHtml from 'remark-html';
import BlogHeader from "./BlogHeader";
import "./BlogPost.css";
import Footer from "./Footer";
import { animated, useSpring } from '@react-spring/web'

function BlogPost() {
    const { t, i18n } = useTranslation();

    const { id } = useParams();

    const [mdContent, setMdContent] = useState('');
    const fadeIn = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: { duration: 1000 }, // Adjust the duration as needed
    });

    useMemo(() => {
        const fetchMdContent = async () => {
            try {
                const response = await fetch(`/article_${id}.md`);
                const text = await response.text();
                setMdContent(text);
                window.scrollTo(0, 0); // Scroll to the top when content is updated
            } catch (error) {
                console.error('Error fetching MD file:', error);
            }
        };

        fetchMdContent();
    }, []);
    const components = {
        code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
                <SyntaxHighlighter style={materialDark} language={match[1]} showLineNumbers>
                    {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        },
        html: ({ value }) => <div dangerouslySetInnerHTML={{ __html: value }} />,
        img: (imageData) => {
            const size = imageData.alt?.match(/(\d+)x(\d+)/); // Regex to look for sizing pattern
            const width = size ? `${size[1]}%` : "100%"; // Use percentage values
            const height = size ? `${size[2]}%` : "auto"; // Use percentage values, 'auto' for proportional height

            return <img src={imageData.src} alt={imageData.alt} width={width} height={height} />;
        },
    };

    const articles = t("blog.articles", { returnObjects: true });

    return (
        <Container fluid className="vh-100 flex-column col p-0" id="blog">
            <BlogHeader />

            <div className="blog-post-container">
                <animated.div className="blog-post-content" style={{ ...fadeIn, minHeight: '150vh', overflowY: 'auto' }}>
                    {mdContent && (
                        <ReactMarkdown remarkPlugins={[remarkGfm, remarkHtml]} components={components}>
                            {mdContent}
                        </ReactMarkdown>
                    )}
                </animated.div>
                <div className="closing-info">
                    <p style={{ textAlign: 'left', fontStyle: 'italic', marginLeft: '50px', marginTop: '20px' }}>
                        <strong>Written by Amine Daoud</strong><br />
                        <em>Published: {articles[id - 1].date}</em>
                    </p>
                </div>
                <div
                    style={{
                        fontFamily: "Comic Sans MS",
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginTop: "5%",
                    }}
                >
                    If you like this article{" "}
                    <a href="https://www.buymeacoffee.com/aminedaoud" target="_blank">
                        <img
                            src="https://cdn.buymeacoffee.com/buttons/default-orange.png"
                            alt="Buy Me A Coffee"
                            height="41"
                            width="174"
                        />
                    </a>
                </div>
                <div>
                    <img
                        src="/images/wave-bye.gif"
                        alt="loading..."
                        style={{ width: "120px", height: "100px", marginTop: "40px" }}
                    />
                </div>
            </div>

            <Footer />
        </Container>
    );
}
export default BlogPost;
