import Container from "react-bootstrap/Container";
import About from "./About";
import Contact from "./Contact";
import Footer from "./Footer";
import Header from "./Header";
import Introduction from "./Introduction";
// import Projects from "./Projects";
import Resume from "./Resume";

function Home() {
  return (
    <Container fluid className="pl-0 border-0 g-0">
      <Header />
      <Introduction />
      <About />
      <Resume />
      {/* <Projects /> */}
      <Contact />
      <Footer />
    </Container>
  );
}

export default Home;
