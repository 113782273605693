import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";

function Contact() {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const sendEmail = (event) => {
    event.preventDefault();
    setResult(null);
    setIsLoading(true);
    axios
      .post("https://send-mail-backend.onrender.com/send", { ...state })
      .then((response) => {
        setResult(response.data);
        setIsLoading(false);
        setState({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch(() => {
        setIsLoading(false);
        setResult({
          success: false,
          message: "Something went wrong. Try again later",
        });
      });
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <Container
      fluid
      className="vh-100 d-flex flex-column col bg-white"
      id="contact"
    >
      <Row className="mt-5"></Row>
      <Row className="mt-5"></Row>
      <Row>
        <Col></Col>
        <Col md={5}>
          <h1>Contact</h1>
          <Form onSubmit={sendEmail}>
            <Form.Group className="mt-4" controlId="formBasicName">
              <Form.Label>
                {t("messageForm.name")} <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={state.name}
                placeholder="Enter your full name"
                onChange={onInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mt-2" controlId="formBasicEmail">
              <Form.Label>
                Email <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={state.email}
                placeholder="Enter your email"
                onChange={onInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mt-2" controlId="formBasicTextField">
              <Form.Label>
                Message <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="message"
                value={state.message}
                placeholder="Enter your message"
                onChange={onInputChange}
                required
              />
            </Form.Group>
            <Button variant="success" type="submit" className="mt-2">
              {t("messageForm.submit")}
            </Button>
          </Form>
        </Col>
        <Col></Col>
      </Row>
      <Row className="mt-1">
        {isLoading && <LoadingSpinner />}
        {result && (
          <p className={`${result.success ? "success" : "error"}`}>
            {result.success ? t("messageForm.success") : t("messageForm.error")}
          </p>
        )}
      </Row>
      <Row className="mt-5"></Row>
    </Container>
  );
}

export default Contact;
