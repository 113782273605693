import Container from "react-bootstrap/Container";
import BlogContent from "./BlogContent";
import BlogHeader from "./BlogHeader";
import Footer from "./Footer";

function Blog() {
  return (
    <Container fluid className="vh-100 flex-column col p-0" id="blog">
      <BlogHeader />
      <BlogContent />
      <Footer />
    </Container>
  );
}

export default Blog;
