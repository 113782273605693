import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en",
    backend: {
      /* translation file path */
      loadPath: "/locales/{{lng}}/{{ns}}.json",

      // path to post missing resources, or a function
      // function(lng, namespace) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      addPath: "/locales/add/{{lng}}/{{ns}}",
    },
    fallbackLng: "en",
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
