import { Slide } from "react-awesome-reveal";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Inroduction() {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="fluid vh-100 border-0 gx-0 g-0 pr-0 pl-0"
      id="home"
      style={{
        backgroundImage: `url(images/backimage5.jpg)`,
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
      }}
    >
      <Row className="text-block mt-5" />
      <Row className="text-block mt-5" />
      <Row className="text-block mt-5" />
      <Row className="text-block mt-5" />
      <Slide>
        <Row className="text-block mt-5 text-white w-100">
          <Col md={12}>
            <h1 id="IntroTitle">{t("introduction.title")}</h1>
            <h2 id="IntroDescription">{t("introduction.description")}</h2>
          </Col>
        </Row>
      </Slide>
    </div>
  );
}

export default Inroduction;
