"use strict";

import React, { useCallback } from "react";
import { Slide } from "react-awesome-reveal";
import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import Particles from "react-particles";
import { Link } from 'react-router-dom';
import { loadFull } from "tsparticles";

import "./Blog.css";

const root = "/api";

function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return windowSize;
}

function BlogContent() {
  ReactGA.initialize("UA-110570651-1");
  ReactGA.pageview(window.location.pathname);

  const { t, i18n } = useTranslation();
  const { width: windowWidth } = useWindowSize();

  const articles = t("blog.articles", { returnObjects: true }).map(function (
    article,
    index
  ) {
    const description = article.description;
    const halfLength = Math.ceil(description.length / 2);
    const firstHalfDescription = description.slice(0, halfLength);
    return (
      <div
        key={index}
        className="post-container"
        style={{
          textAlign: "left",
          borderRadius: "10px",
          boxShadow: "5px 5px 5px #888888",
          marginBottom: "35px",
        }}
      >
        <div>
          <h2>{article.title}</h2>
        </div>
        <Link to={`/blog/${article.id}`}>
          {windowWidth <= 600 ? `${firstHalfDescription}...` : description}
        </Link>

        <div className="info">
          <h4>Written by: Amine Daoud</h4>
        </div>
      </div>
    );
  });

  return (
    <Container fluid id="BlogContent" className="d-flex flex-column mt-5">
      <Slide right duration={1000}>
        <div>
          <Col xs={12} md={12}>
            <div className="widget-title" style={{ overflowX: "hidden" }}>
              <h1>Welcome to my personal blog</h1>
            </div>
          </Col>
        </div>
        <div className="mt-4 mx-4" style={{ overflowX: "hidden" }}>
          {articles}
          {/* <div
            style={{
              fontFamily: "Comic Sans MS",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "32px",
            }}
          >
            If you like my articles{" "}
            <a href="https://www.buymeacoffee.com/aminedaoud" target="_blank">
              <img
                src="https://cdn.buymeacoffee.com/buttons/default-orange.png"
                alt="Buy Me A Coffee"
                height="41"
                width="174"
              />
            </a>
          </div>
          <div>
            <img
              src="images/wave-bye.gif"
              alt="loading..."
              style={{ width: "120px", height: "100px", marginTop: "40px" }}
            />
          </div> */}
        </div>
        <div className="text-block mt-5" />
      </Slide>
    </Container>
  );
}

const Background = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#0d47a1",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            directions: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 6,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
  );
};

export default BlogContent;
