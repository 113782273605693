import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { useTranslation } from "react-i18next";
import Language from "./Language";

function BlogHeader() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Navbar bg="light" expand="lg" fixed="top">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link className="me-4 ms-2" href="/"> {t("navBar.home")}</Nav.Link>
              <Nav.Link className="me-4" href="/blog"> {t("navBar.blog")}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Custom CSS for modifying collapsed Navbar width */}
      <style>
        {`
              @media (max-width: 767.98px) {
                  .navbar-toggler.collapsed {
                    width: 17% !important; /* You can adjust the width as needed */
                  }
                }
              `}
      </style>
    </>
  );
}

export default BlogHeader;
