import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import SkillBar from "react-skillbars";
import Rate from "./Rate";

function Resume() {
  const { t, i18n } = useTranslation();

  const skills = t("skills.list", { returnObjects: true });
  var i;
  for (i = 0; i < skills.length; i++) {
    skills[i].type = skills[i]["name"];
    delete skills[i].name;
  }

  const Education = t("education.experiences", { returnObjects: true }).map(
    function (item, index) {
      return (
        <React.Fragment key={index}>
          <Row className="mb-5"></Row>

          <Row>
            <Col md={1}> </Col>
            <Col md={2}>
              {index === 0 && (
                <h2 className="text-decoration-underline">
                  {" "}
                  {t("education.title")}{" "}
                </h2>
              )}
            </Col>
            <Col md={1}> </Col>
            <Col md={5}>
              <div className="row text-start">
                <h4>
                  {item.university}, {item.date}, {item.location}
                </h4>
                <p className="fst-italic">{item.degree}</p>
              </div>
            </Col>
            <Col md={1}>
              <Image
                src={item.image}
                className="img-responsive"
                width="50"
                height="50"
              />
            </Col>
          </Row>
          {item.description && (
            <Row className="text-block mt-0">
              <Col md={1}> </Col>
              <Col md={2}>
                <h2 className="text-decoration-underline"> </h2>
              </Col>
              <Col md={1}> </Col>
              <Col md={5}>
                <div className="row text-start">
                  <h4> </h4>
                  <p className="fst-italic">{item.description}</p>
                </div>
              </Col>
              <Col md={1}></Col>
            </Row>
          )}
          {item.keywords && (
            <Row className="text-block mt-0">
              <Col md={4}> </Col>
              <Col md={5} className="text-start">
                {item.keywords.map((keyword, index) => {
                  return (
                    <span key={index} className="badge bg-secondary me-1">
                      {keyword}
                    </span>
                  );
                })}
              </Col>
            </Row>
          )}
          <Row className="mb-5"></Row>
        </React.Fragment>
      );
    }
  );

  const Work = t("work.experiences", { returnObjects: true }).map(function (
    item,
    index
  ) {
    return (
      <React.Fragment key={index}>
        {index !== 0 && <Row className="mb-5"></Row>}
        <Row>
          <Col md={1}></Col>
          <Col md={2}>
            {index === 0 && (
              <h2 className="text-decoration-underline"> {t("work.title")} </h2>
            )}
          </Col>
          <Col md={1}></Col>
          <Col md={4} sm={6} className="mb-3 mb-md-0 ps-4 pe-3 pe-md-0">
            <div className="row text-start">
              <h4>{item.company}</h4>
              <p className="fst-italic">{item.position}</p>
              <p>
                {item.date} | {item.location}
              </p>
              {item.description}
              <ul>
                {item.tasks.map((desc) => (
                  <li key={desc}>{desc}</li>
                ))}
              </ul>
            </div>
          </Col>
          <Col md={2} sm={3}>
            <Image
              src={item.image}
              className="img-responsive"
              width="50"
              height="50"
            />
          </Col>
        </Row>
        {item.keywords && (
          <Row className="text-block mt-0">
            <Col md={4} sm={3}></Col>
            <Col md={6} sm={8} className="text-start">
              {item.keywords.map((keyword, index) => (
                <span key={index} className="badge bg-secondary me-1">
                  {keyword}
                </span>
              ))}
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  });


  return (
    <Container
      fluid
      className="h-auto d-flex flex-column bg-white"
      id="resume"
      style={{ fontfamily: "Helvetica" }}
    >
      <Row className="text-block mt-5" />
      <Row className="text-block mt-5" />
      <Row className="mt-3" />
      {Work}

      <Row className="text-block mt-5">
        <Col md={1}> </Col>
        <Col md={10}>
          <hr />
        </Col>
        <Col md={1}> </Col>
      </Row>

      {Education}

      <Row className="text-block mt-5">
        <Col md={1}></Col>
        <Col md={10}>
          <hr />
        </Col>
        <Col md={1}></Col>
      </Row>

      <Row className="text-block mt-5" />
      <Row className="text-block mt-5" />
      <Row>
        <Col md={1}> </Col>
        <Col md={2}>
          <h2 className="text-decoration-underline"> {t("skills.title")} </h2>
        </Col>
        <Col md={1}> </Col>
        <Col md={5}>
          <div className="row text-start">
            <SkillBar
              skills={skills}
              height={30}
              animationDuration={500}
              animationDelay={500}
            />
          </div>
        </Col>
      </Row>

      <Row className="text-block mt-5">
        <Col md={1}></Col>
        <Col md={10}>
          <hr />
        </Col>
        <Col md={1}></Col>
      </Row>

      <Row className="text-block mt-5" />
      <Row className="text-block mt-5" />
      <Row>
        <Col md={1}> </Col>
        <Col md={2}>
          <h2 className="text-decoration-underline">
            {" "}
            {t("languages.title")}{" "}
          </h2>
        </Col>
        <Col md={1}> </Col>
        <Col md={5}>
          <div className="row text-start">
            {t("languages.list", { returnObjects: true }).map(
              ({ language, level }) => (
                <Rate key={language} keyword={language} level={level} />
              )
            )}
          </div>
        </Col>
        <a href={t("contact.link")} rel="nofollow" download="CV_Amine_Daoud">
          <button
            style={{
              backgroundColor: "grey",
              marginTop: "30px",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            <i className="fa fa-download"></i> {t("contact.download")}
          </button>
        </a>
      </Row>
    </Container>
  );
}

export default Resume;
